import React from 'react';
import { 
  Route, 
  Routes,  
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Question from './pages/Question';

import RootLayout from './layouts/RootLayout';
import Page_404 from './pages/Page_404';

import { Provider } from 'react-redux'
import store from './redux/store'
import UnderConstruction from './pages/UnderConstruction';
import Subject from './pages/Subjects';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path='dashboard' element={<Dashboard />} />
      <Route path='subjects' element={<Subject />} />
      <Route path='subjects'>
        <Route path='biology' element={<UnderConstruction/>}>
          <Route path='gcse' element={<UnderConstruction/>} />
          <Route path='a-level' element={<UnderConstruction/>} />
        </Route>
        <Route path='chemistry' element={<UnderConstruction/>} >
          <Route path='gcse' element={<UnderConstruction/>} />
          <Route path='a-level' element={<UnderConstruction/>} />
        </Route>
        <Route path='physics' element={<UnderConstruction/>} >
          <Route path='gcse' element={<UnderConstruction/>} />
          <Route path='a-level' element={<UnderConstruction/>} />
        </Route>
        <Route path='mathematics' element={<UnderConstruction/>} >
          <Route path='gcse' element={<UnderConstruction/>} />
          <Route path='a-level' element={<UnderConstruction/>} />
        </Route>
        <Route path='further-mathematics' element={<UnderConstruction/>} >
          <Route path='gcse' element={<UnderConstruction/>} />
          <Route path='a-level' element={<UnderConstruction/>} />
        </Route>
        <Route path='computer-science' element={<UnderConstruction/>} >
          <Route path='gcse' element={<UnderConstruction/>} />
          <Route path='a-level' element={<UnderConstruction/>} />
        </Route>
      </Route>
      <Route path='*' element={<Page_404 />} />
    </Route>
  )
)

function App() {
  return (
    <Provider store={store} >
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
