import { createSlice } from '@reduxjs/toolkit'

export const navigation = createSlice({
    name: 'nagivation',
    initialState: {
        value: false
    },
    reducers: {
        toggle: (state, action) => {
            if (action.payload === 1){
                state.value = false
            }
            else if (state.value){
                state.value = false
            } else {
                state.value = true
            }
        }
    }
})

export const {toggle} = navigation.actions;

export default navigation.reducer;