import styles from "../styles/subjects.module.css";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";
const Subject = () => {
  const subjects = [
    "Biology",
    "Chemistry",
    "Physics",
    "Mathematics",
    "Further Mathematics",
    "Computer Science",
  ];
  const activate = useSelector((state) => state.navigation.value);
  const container = [];
  return (
    <section>
      <div className={`${activate ? styles.mobileremove : ""}`}>
        <Header />
        <div className={styles.layoutc}>
          <section className={styles.sect}>
            <h2 className={styles.reducedsize}>Subjects</h2>
            <div className={styles.subjects}>
              {subjects.forEach((element, idx) => {
                if (element === "") {
                  container.push(<div key={idx * 42}></div>);
                } else {
                  container.push(
                    <NavLink
                      to={`/subjects/${element
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}
                    >
                      <div
                        alt={element}
                        key={121 + idx * 2}
                        className={styles.subjectbox}
                      >
                        <div>
                          <div>
                            <div className="enablecursor">
                              <section className={styles.subjectcon}>
                                <img
                                  src={`/${element
                                    .replace(/\s+/g, "-")
                                    .toLowerCase()}.svg`}
                                  width="100px"
                                  height="100px"
                                  alt={element}
                                />
                                {element}
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  );
                }
              })}
              {container}
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Subject;
