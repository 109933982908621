import React from 'react'

import Header from '../components/Header'
import Hero1 from '../components/main/Hero1'
import Hero2 from '../components/main/Hero2'
import Hero3 from '../components/main/Hero3'
import Hero4 from '../components/main/Hero4'
import Footer from '../components/Footer'
import ALSubjects from './AL_Subjects'
import GCSubjects from './GC_Subjects'

function home() {
  return (
    <div>
      <Header />
      <Hero1 />
      <Hero2 />
      <Hero3 />
      <Hero4 />
      <ALSubjects />
      <GCSubjects />
      <Footer />
    </div>
  )
}

export default home