import React from "react";
import Header from "../components/Header";

type Props = {};

export default function UnderConstruction({}: Props) {
  return (
    <div className="flex_container">
      <section className="fill">
        <Header />
      </section>
      <div className="flex_box">
        <img
          src="/underconstruction.svg"
          width={320}
          height={320}
          alt="side image of a woman looking at a graph of her success using the neural hub"
          className="image"
        />
        <div className="underconstruction">
            Currently Under Construction
        </div>
        <div className="underconstruction_text">
          We are working as fast as we can to build this you will soon be able
          to access this
        </div>
      </div>
    </div>
  );
}
