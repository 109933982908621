import React from "react";
import styles from '../../styles/home.module.css'
import { NavLink } from "react-router-dom";
const Hero1 = () => {
  return (
    <div className={styles.flex_center}>
      <main className={styles.layout}>
        <section className={styles.sect}>
          <div>
            <h1 className={styles.heading1}>
              Studying made easier at All Levels
            </h1>
          </div>
          <div className={styles.sectc}>
            Providing a free resource for student to improve their grades with
            and monitor their progress easily.
          </div>
          <div className={styles.sectc}>
            <section className={styles.btncon}>
              <NavLink to='/getting-started' className={styles.btntest}>Get Started</NavLink>
              <NavLink to='/subjects' className={styles.btntest}>Choose your Subjects</NavLink>
            </section>
          </div>
        </section>
        <section className={styles.sect}>
          <div className={styles.imgcorrection}>
            <img
              src="/certificate.svg"
              alt="side image of a man holding a certificate because he used the neural hub"
              className='imagemain'
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default Hero1;
