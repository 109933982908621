import React from "react";
import styles from "../styles/footer.module.css";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <section className={styles.flex_container}>
      <footer className={styles.width100}>
      <div className={styles.container}>
        <div>
          <div>
            <div className={styles.logo}>The Neural Hub</div>
          </div>
        </div>
        <div className={styles.seperate}>
            <div className={`${styles.footerg} ${styles.dis}`}>Subjects</div>
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
              <NavLink to="/subjects/biology">
                <div className={styles.def_footer_font}>Biology</div>
              </NavLink>
            </div>
          </div>
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
              <NavLink to="/subjects/chemistry">
                <div className={styles.def_footer_font}>Chemistry</div>
              </NavLink>
            </div>
          </div>
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
              <NavLink to="/subjects/physics">
                <div className={styles.def_footer_font}>Physics</div>
              </NavLink>
            </div>
          </div>
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
              <NavLink to="/subjects/mathematics">
                <div className={styles.def_footer_font}>Mathematics</div>
              </NavLink>
            </div>
          </div>
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
              <NavLink to="/subjects/further-mathematics">
                <div className={styles.def_footer_font}>Further Mathematics</div>
              </NavLink>
            </div>
          </div>
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
              <NavLink to="/subjects/computer-science">
                <div className={styles.def_footer_font}>Computer Science</div>
              </NavLink>
            </div>
          </div>
        </div>
        <div className={styles.seperate}>
            <div className={`${styles.footerg} ${styles.dis}`}>Company</div>
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
              <NavLink to="/getting-started">
                <div className={styles.def_footer_font}>How Does It Work</div>
              </NavLink>
            </div>
          </div>
          <NavLink to="/blogs">
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
                <div className={styles.def_footer_font}>Blog</div>
            </div>
          </div>
          </NavLink>
          <NavLink to="/about-us">
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
                <div className={styles.def_footer_font}>About Us</div>
            </div>
          </div>
          </NavLink>
          <NavLink to="/contact-us">
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
                <div className={styles.def_footer_font}>Contact Us</div>
            </div>
          </div>
          </NavLink>
          <NavLink to="/faq">
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
                <div className={styles.def_footer_font}>FAQ</div>
            </div>
          </div>
          </NavLink>
        </div>
        <div className={styles.seperate}>
            <div className={`${styles.footerg} ${styles.dis}`}>Legal</div>
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
              <NavLink to="/tos">
                <div className={styles.def_footer_font}>Terms of Service</div>
              </NavLink>
            </div>
          </div>
          <div className={styles.footer_list}>
            <div className={styles.footer_item}>
              <NavLink to="/privacy-policy">
                <div className={styles.def_footer_font}>Privacy Policy</div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        Copyright © 2023 The Neural Hub. All Rights Reserved
      </div>
    </footer>
    </section>
  );
};

export default Footer;
