import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";

type Props = {};

function Page_404({}: Props) {
  return (
    <div>
      <Header />
      <div id="notfound">
        <div className="notfound">
          <div className="flex_box_wp">
            <div>
              <div className="notfound-404">
                <img src="404.svg" alt="404" width={200} />
              </div>
            </div>
            <div>
              <h2>Page Not Found!</h2>
              <p>
                The page you are looking for might have been removed had its
                name changed or is temporarily unavailable.
              </p>
              <NavLink to="/">Back To Homepage</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page_404;
