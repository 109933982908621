import React from "react";
import styles from "../../styles/home.module.css";
const Hero4 = () => {
  return (
    <div className={styles.bgr}>
      <main className={styles.layout}>
        <section className={styles.sect}>
          <div className={styles.imgcorrection1}>
            <img
              src="/analyse.svg"
              alt="side image of a man looking at his progress"
              className='imagemain'
            />
          </div>
        </section>
        <div className={styles.sect}>
          <section>
            <h2 className={styles.righttxt}>Analyse</h2>
            <div className={styles.righttxt_l}>
              Providing a free resource for student to improve their grades with
              and monitor their progress easily.
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Hero4;
