import styles from "../styles/subjects.module.css";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
const GCSE = () => {
  const subjects = [
    "Biology",
    "Chemistry",
    "Physics",
    "Mathematics",
    "Further Mathematics",
    "Computer Science",
  ];
  const activate = useSelector((state) => state.navigation.value);
  const container = [];
  return (
    <section className={styles.flex_container_gray}>
        <div className={`${activate ? styles.mobileremove : ""}`}>
      <div className={styles.layout}>
        <section className={styles.sect}>
          <h2 className={styles.reducedsize}>GCSE Subjects</h2>
          <div className={styles.subjects}>
            {subjects.forEach((element, idx) => {
              if (element === "") {
                container.push(<div key={3232 + idx * 42}></div>);
              } else {
                container.push(
                  <div
                    key={2004 + idx * 5}
                    alt={element}
                    className={styles.subjectbox}
                  >
                    <NavLink
                      to={`/subjects/${element
                        .replace(/\s+/g, "-")
                        .toLowerCase()}/gcse`}
                    >
                      <div>
                        <div>
                          <div className="enablecursor">
                            <section className={styles.subjectcon}>
                              <img
                                src={`/${element
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()}.svg`}
                                width="100px"
                                height="100px"
                                alt={element}
                              />
                              {element}
                            </section>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                );
              }
            })}
            {container}
          </div>
        </section>
      </div>
    </div>
    </section>
  );
};

export default GCSE;
