import React from "react";
import styles from "../styles/header.module.css";
import { NavLink } from "react-router-dom";

export default function Header() {
  return (
    <header className={styles.root}>
      <nav>
        <header className={styles.header}>
          <nav className={styles.navbar}>
            <section className={styles.logo}>
              <NavLink className={styles.removeHL}to="/">The Neural Room</NavLink>
            </section>
            <section className={styles.noside}>
              <NavLink className={styles.removeHL}to="/about-us">
                <div className={styles.sidetext}>About Us</div>
              </NavLink>
              <NavLink className={styles.removeHL}to="/blogs">
                <div className={styles.sidetext}>Blogs</div>
              </NavLink>
              <NavLink className={styles.removeHL}to="/subjects">
                <div className={styles.sidetext}>Subjects</div>
              </NavLink>
              <NavLink className={styles.removeHL}to="/gcse">
                <div className={styles.sidetext}>GCSE</div>
              </NavLink>
              <NavLink className={styles.removeHL}to="/a-level">
                <div className={styles.sidetext}>A-Level</div>
              </NavLink>
              <section className={styles.sidetext}>
                <NavLink className={styles.removeHL}to="/sign-in">Sign In</NavLink>
              </section>
            </section>
          </nav>
        </header>
      </nav>
    </header>
  );
}
