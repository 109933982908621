import React from "react";
import styles from "../../styles/home.module.css";
const Hero3 = () => {
  return (
  <div className={styles.flex_container}>
    <div className={styles.layout}>
      <section className={styles.sect}>
        <div className={styles.headingleft}>
          <h2 className={styles.heading1}>Excel</h2>
        </div>
        <div className={styles.sectc}>
          Providing a free resource for student to improve their grades with and
          monitor their progress easily.
        </div>
      </section>
      <section className={styles.sect}>
        <div className={styles.imgcorrection}>
          <img
            src="/graphexcel.svg"
            alt="side image of a woman looking at a graph of her success using the neural hub"
            className='imagemain'
          />
        </div>
      </section>
    </div>
  </div>
  );
};

export default Hero3;
